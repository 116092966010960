.Description {
        color: #34425A;
        padding-left: 100px;
        font-size: larger;
        /* padding-bottom: 10%; */
        padding: 4% 10% 6% 10%!important;  
}

.Description img{
        margin-bottom: 5px;
}

.cont {
        min-height: calc(100vh - 80px);
}

.row{
        --bs-gutter-x:unset !important;
}