.zygosity {
    height: 23px;
    padding: 0 4px;
}

/* div */
.digenic-table {
    min-width: fit-content;
    background-color: #ffffff !important;
    border-spacing: 0 16px;
    border-collapse: separate;
}

/* header */
.digenic-table-header {
    width: 100%;
    padding-right: 48px;
    table-layout: fixed;
    min-width: 850px;
    border-collapse: separate;
    border-spacing: initial;
    color: #ffffff !important;
    background-color: rgba(52, 77, 102, 0.95) !important;
}

.first-header {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    text-align: center;
    border-bottom: solid 2px #ffffff;
}

.second-header {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    margin-bottom: 16px;
}

.digenic-table-header-row th {
    padding: 8px;
    border-right: solid 2px #ffffff;
    text-align: center;
}

.digenic-table-header-row>th:last-child {
    border-right: none !important;
}

.mechanism-header {
    width: 24% !important;
}

.gene-header {
    width: 26%;
}

.comb-header {
    width: 48%;
}

/* accordion */
.digenic-table-body {
    width: 100%;
    table-layout: fixed;
    min-width: 850px;
}

.digenic-table-row {
    border-radius: 4px !important;
    width: 100%;
}

.digenic-table-row>td,
.digenic-table-variantRow td {
    padding: 24px 8px;
    vertical-align: middle;
}

.digenic-table-row>td:first-child,
.digenic-table-header-row>th:first-child {
    padding-left: 16px !important;
}

.digenic-table-row>td:last-child,
.digenic-table-header-row>th:last-child {
    padding-right: 16px !important;
}

.digenic-table-variantRow td {
    padding: 0px 8px 24px 8px !important;
}

.zygosity-col svg {
    margin-right: 0px !important;
}

/* Cols dimension */
.zygosity-col {
    width: 4% !important;
}

.predict-col {
    width: 15% !important;
}

.predict-col-img {
    width: 9% !important;
}

.predict-col-img img {
    max-width: 100%;
}

.gene-col {
    width: 8% !important;
}

.hpo-col {
    width: 24% !important;
}

.hgvs-col {
    width: 14% !important;
}

.hgvs-col span {
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
    display: block;
    max-width: fit-content;
}

.hgvs-tooltip {
    --bs-tooltip-bg: #ffffff !important;
    opacity: 1;
    color: #344d66;
    position: fixed;
    pointer-events : none !important;
}

.custom-gene-tooltip .tooltip-inner{
    max-width: fit-content;
    white-space: nowrap;
}

.hgvs-tooltip .tool {
    color: #344d66 !important;
    background-color: #ffffff !important;
}

.hgvs-tooltip .tooltip-inner{
    max-width: fit-content;
    white-space: nowrap;
}

.hpo-list {
    white-space: pre-wrap;
}