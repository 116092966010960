.ul-custom{
    margin-bottom: 0px;
}

.custom-modal{
    max-height: 85vh !important;
    overflow-y: auto !important;
}

.modal-dim{
    max-width: 60vw !important;
    max-height: 10% !important;
}

#privacy-policy-modal .row div{
    margin-bottom: 16px;
}