.headerBar {
      height: 80px;
      margin: 0 0 40px;
      box-shadow: 0 2px 16px 0 rgba(29, 29, 29, 0.08);
      background-color: #344d66;
}

Col {
      height: 100%;
}

.DIVAs-logo img{
      height: 50px;
      margin-top: 15px;
      cursor: pointer;
}
/* .DIVAs-logo {
      padding-right: 10px;
} */

.help1,
.login1{
      padding: 25px 10px 28px 10px;
}

.menu1,
.user1 {
      padding: 25px 5px 5px 5px;
      z-index: 10;
}

.menu1 li,
.user1 li {
      padding: 15px 5px 15px 5px;
}

.help {
      text-decoration: none;
      color: #ffffff;
}

.help:hover {
      text-decoration: underline;
      color: #ffffff;
      cursor:pointer;
}

.nav li span,
.nav li a {
      /* width: 70px; */
      margin: 9px;
}

.nav li span,
.nav li a,
.nav ul li {
      font-size: 16px;
      font-family: Roboto;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1;
      letter-spacing: normal;
      color: #ffffff;
}

.right-side {
      text-align: right !important;
      padding-right: 0px !important;
}

.nav {
      justify-content: flex-end;
}

svg {
      margin-right: 5px;
}

.nav ul li {
      position: relative;
      list-style: none;
}

.nav li ul {
      display: none;
}

.nav li:hover ul {
      display: block;
      text-align: center;
      padding-left: 0px;
      margin-top: 15px;
      background-color: #2b384e;
}

.menu1:hover,
.login1:hover,
.user1:hover {
      background-color: #2b384e;
      cursor: pointer;
}

.nav li:hover ul li:hover {
      color: white;
      background-color: #344d66;
}