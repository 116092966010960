.img-link{
  margin-bottom:5px !important;
}

#edges:hover{
  cursor: pointer;
}

.edge_details_standard_evidence_table{
  margin: 0 12px 12px 12px;
  border-collapse: separate;
  border-spacing: 5px;
}

.edge_details_standard_evidence_table tr td:first-child{
  text-align: end;
  background: #E6E6E6;
  white-space: nowrap;
}

div[name='STRING_network']{
  display: flex;
}

div[name='STRING_network'] table[name='score-table']{
  white-space: nowrap;
}

#svg_string_logo text{
  font-size: 90px !important;
}