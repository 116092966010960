.validPhenotype, .invalidPhenotype, .phenotypeList{
    display: flex;
}

.phenotype-container{
    display: inline-flex;
}

.buttonValid,
.buttonInvalid { 
    border-radius: 16px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 16px;
    display:inline-flex;
}

.buttonValid {
    background: rgba(90,190,190, 0.25);
}

.buttonInvalid{
    background: rgba(238,131,115, 0.25);
}

.phenotype-container:hover{
    margin-right: 0px;
}

.phenotype-container:hover .close, .buttonInvalid:hover .close{
    display: block;
    /* margin-right: 4px; */
}

.phenotype{
    /* vertical-align: middle; */
    line-height: 1;
    color: #344d66;
    opacity:100 !important;
    padding: 8px;
    margin-bottom: 0px;
}

.close{
    width: 18px;
    height: 18px;
    text-align: center;
    right: 5px;
    color:#efefef;
    background-color: #344d66;
    cursor: pointer;
    display: none;
    border-radius: 25px;
    /* margin-right: 16px; */
    margin: -5px 7px 0 -25px;
}

.phenotype-info{
    margin: auto 6px auto auto;
    cursor: pointer;
}

.tool-phenotype{
    text-align: left;
    color: #344d66;
    font-family: Roboto;
    font-size: 12px;
     font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: #ffffff;
    margin: 0;
}

.tooltip-phenotype{
    --bs-tooltip-bg:#ffffff !important;
    position: fixed;
}

.phenotype-synonym{
    white-space: pre-wrap;
    margin-bottom: 0 !important;
}

.hint{
    /* display: ; */
    position: absolute;
    width: 43%;
    margin-left:10%;
    border: solid 1px #efefef;
    border-top:0px;
    background-color: #ffffff;
    color:#344d66;
    z-index: 10;
    font-family: Roboto;
}

.no-phenotype{
    margin:0;
    text-align: center;
    font-size: larger;
    padding: 10px 0px;
    color:#888;
}

.phenotype-name{
    padding: 5px 20px;
    margin:0;
    outline: none;
}

.phenotype-name:focus{
    background-color: #efefef;
    cursor: pointer;
}

.tooltip-phenotype .tooltip-inner{
    border: thin solid #efefef;
    /* background-color: inherit !; */
    background-color: #ffffff !important;
}

.tooltip.show{
    opacity: 1 !important;
}

.tooltip-arrow::before{
    /*border-right-color: #ffffff !important;*/
}

#tooltip{
    position: fixed;
    margin-bottom: 5px;
}