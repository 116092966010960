.typeSearch {
    border-radius: 25px 0 0 25px;
    height: 48px;
    /* width: 120px; */
    width: 11%;
    box-shadow: 0 10px 16px 0 rgba(29, 29, 29, 0.08) !important;
    border: solid 1px #efefef;
    background-color: #ffffff;
    outline: none;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    color: #344d66;
    vertical-align: middle;
    /*ToDo da capire come allineare il testo al centro nelle select su safari*/
    /* -webkit-appearance: none;
    background: url('../../../Images/arrow-down-blue.svg') no-repeat right;
    background-size: 18px;
    text-align: -webkit-center; */
}

.typeSearch:hover {
    cursor: pointer;
}