.typeAssembly {
        height: 48px;
        /* border-radius: 0 5px 5px 0;  */
        width: 11%;
        border-left: none;
        box-shadow: 0 10px 16px 0 rgba(29, 29, 29, 0.08) !important;
        border: solid 1px #efefef;
        background-color: #ffffff;
        outline: none;
        text-align: center;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.2px;
        color: #344d66;
        vertical-align: middle;
}

.typeAssembly:hover {
        cursor: pointer;
}

.inputSearchVar {
        width: 64%;
}

.inputSearchPh, .inputSearchGene {
        width: 75%;
}

.inputSearchVar::-webkit-input-placeholder,
.inputSearchPh::-webkit-input-placeholder,
.inputSearchGene::-webkit-input-placeholder{
        opacity: 0.6;
}

.inputSearchVar::-moz-placeholder,
.inputSearchPh::-moz-placeholder,
.inputSearchGene::-moz-placeholder{
        opacity: 0.6;
}

.inputSearchVar,
.inputSearchPh, .inputSearchGene {
        height: 48px;
        padding: 0 16px;
        box-shadow: 0 10px 16px 0 rgba(29, 29, 29, 0.08) !important;
        border: solid 1px #efefef;
        border-right: none;
        background-color: #ffffff;
        outline: none;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.5px;
        color: #344d66;
        vertical-align: middle;
}

.geneRef, .phenotypeRef{
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: 0.25px;
        padding: 7.5px 0px 7.5px 16px;
        max-width: 90%;
        overflow: auto;
}
