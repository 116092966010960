.infoTable-content-gene1,
.infoTable-content-gene2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 190px;
  /*cursor: pointer;*/
}

.infoTable-content-gene1 {
  margin-right: 10px !important;
}

.infoTable-content-gene2 {
  margin-left: 10px !important;
}

.general-col {
  width: 50%;
}

.col-label-general {
  text-align: end;
}

.general-col img {
  transform: scale(0.8);
}

#mechanism {
  text-align: center;
}

.infoTable-content-gene1 {
  text-align: -webkit-right;
  text-align: -moz-right;
}