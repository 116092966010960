.searchPanel {
    padding-top: 3.5%;
}

.row-gene-info, .row-results {
    margin-top: 25px !important;
}

.row-results{
    margin-bottom: 40px;
}

.validity-info {
    height: 24px;
    text-align: center;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #344d66;
    /* padding: 6px 0; */
}

.numberInvalid, .numberValid, .numberSynonym {
    display: inline-block;
    width: 24px !important;
    height: 24px !important;
    margin: 0 5px;
    border-radius: 25px;
    border: solid 1px #efefef;
    background-color: #ffffff;
    vertical-align: middle;
    padding-top: 4px;
}

.validity-info p {
    display: inline-flex;
    margin-right: 15px;
}

.numberValid {
    color: #5abeba;
}

.numberInvalid{
    color:#ee8373;
}

.warning {
    margin-top: 25px !important;
    text-align: center;
    color: #f25656;
}