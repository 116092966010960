.spinner {
  margin: 40px auto 15px;
  width: 100px;
  text-align: center;
  white-space: nowrap;
}

.spinner > div {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  background-color: #22BAA0;

  /*border-radius: 100%;*/
  border: solid 6px #22BAA0;
  display: inline-block;
  -webkit-animation: bouncedelay 1s infinite ease-in-out;
  animation: bouncedelay 1s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.48s;
  animation-delay: -0.48s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce3 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner.loginSpinner > div {
    background-color: #FFF;
    border: none;
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.5); opacity: .5; }
  40% { -webkit-transform: scale(1.0); opacity: 1; }
}

@keyframes bouncedelay {
  0%, 80%, 100% {
    transform: scale(0.5);
    -webkit-transform: scale(0.5);
    opacity: .5;
  } 40% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
    opacity: 1;
  }
}