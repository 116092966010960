.exampleButton {
    background-color: #ffffff;
    top: 490px;
    width: 92px;
    height: 32px;
    border-radius: 5px;
    border: solid 1px #5abeba;
}

.exampleButton:hover {
    background-color: rgba(90, 190, 186, 0.08);
}

.Example {
    margin: 0;
    font-family: Roboto;
    font-size: 12px;
    font-weight: bold;
    color: #344d66;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.8px;
    text-align: center;
}

.ex {
    background-color: #344d66;
    border-radius: 5px 5px 5px 5px;
    width: 200px !important;
    padding: 10px;
    /* text-align: center; */
    color: #ffffff;
    border: solid 1px #2e455d;
    /* right: 316px; */
    /* right: 29%; */
    /* se non metto la position absolute magari servono, da vedere */
     margin-top: 5px;
     margin-bottom: 10px;
    /* align-self: flex-start; */
}

.examples {
    cursor: pointer;
    ;
    text-decoration: underline;
    text-underline-offset: 3px;
}

.examples:hover {
    color: #d7d7d7;
    ;
}

.examples,
.type0,
.type1 {
    font-family: Roboto;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    padding: 5px;
    margin-bottom: 0px;
}

.type0,
.type1 {
    font-weight: bold;
}

.type1 {
    margin-top: 12px;
}