.cancel {
    margin-left: 8px;
}

.save {
    margin-left: 10px;
}

.no-wrap{
    white-space: nowrap;
}

.data input {
    padding-left: 10px;
}

.profile-pass .col-eye {
    display: block;
}

.profile-label {
    font-size: 15px;
    font-family: Roboto;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #344d66;
    cursor: pointer;
}

.sez {
    background-color: #f6f6f6;
    padding-top: 10px;
    margin-bottom: 10px;
}

.data input,
.data select {
    width: 100%;
}

.data label {
    margin-top: 15px;
    margin-left: 10px;
}

.modal-90w {
    max-width: 46% !important;
    max-height: 50% !important;
    /* min-width: 575px; */
}

.errorMessage {
    color: #f25656;
    text-align: center;
    font-family: Roboto;
    padding-top: 5px;
    font-size: .875em;
}

.successMessage {
    color: #344d66;
    text-align: center;
    font-family: Roboto;
    padding-top: 5px;
    font-size: .875em;
}

.iconSuccess {
    color: #5abeba;
}

.saveXcol{
    max-width: fit-content !important;
}

.sez .data div:first-child {
    min-width: 160px;
    max-width: 160px;
}