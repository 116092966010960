.modal-w-cust {
    max-width: 47% !important;
    /*max-width: fit-content !important;*/
    max-height: 80vh !important;
}

.li-helper{
border:  1px solid red !important;
}
li:first-child > h6{
    margin-top:8px;
}

/*li > h6{*/
/*    margin-bottom:8px;*/
/*}*/

.download-icon{
    display: inline-block;
    color: #337ab7;
    margin-left: 8px;
    transform: scale(1.3);
}
.tabledim{
    width: 65%;
}

.img-button{
    border-radius:24px;
    /*height: 20px;*/
}

.img-transform{
    transform: scale(0.9);
}

.td-dimension{
    width: 50% !important;
}
.td-second{
    display: inline-block !important;
}
.theadspace{
    text-align:center;
}

.show{
    /*display:inline-block;*/
    display:block;
    white-space: initial;
    /*width: 95%;*/
}

.notshow{
    display:none;
}

.titleHelpCenter{
    display: inline-block;
    padding-bottom: 0px;
    font-weight: 500;
}

hr{
    margin: 8px 0px 16px 0px !important;
}
.subtitleHelpCenter{
    /*margin-bottom: 0px;*/
    padding-bottom: 0px;
    display: inline-block;
    font-weight: 500;
    /* margin: 6px 0px; */
}


.subtitleHelpCenter:hover{
    cursor: pointer;
}

.titleHelpCenter:hover{
    cursor: pointer;
}

svg{
    transition:0.5s;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-fill-mode: forwards;
    -ms-animation-fill-mode: forwards;
    -o-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    margin-bottom: 2.5px;
}

.svgClickOpen{
    animation-fill-mode: forwards;
    transform:rotate(-180deg); /* How many times it'll rotate = degrees÷360 */
}

.help-modal div{
    margin-bottom: 8px;
}