custom-li::marker{
    font-weight: bold;
    font-size: 1.10em !important;
}

custom-li>b{
    font-size: 1.10em !important;
}

/*ol > li::marker {*/
/*    font-weight: bold;*/
/*    font-size: 1.10em !important;*/
/*}*/

/*li>b{*/
/*    font-size: 1.10em !important;*/
/*}*/
.custom-modal{
    max-height: 85vh !important;
    overflow-y: auto !important;
}

.modal-dim{
    max-width: 50% !important;

}