.gene-result-info {
    margin: 0px;
    padding: 8px;
}

.tooltip-copy{
    position: fixed;
    background-color: rgb(52, 77, 102);
    padding: 0.25rem 0.5rem;
    color: white;
    border-radius: 0.375rem;
    font-family: Roboto;
    text-align: left;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
}

.tooltip-copy:after{
    content: "";
    display: block;
    position: absolute;
    border-style: solid;
    border-width: 8px;
    border-color: rgba(52, 77, 102) transparent transparent transparent;
    left: calc(50% - 8px);
}

.gene-result-info:hover,
.transcript-info:hover {
    cursor: pointer;
    background-color: rgba(52, 77, 102, 0.04) !important;
}

.tdhgvs{
    white-space: nowrap;
    /*display: block;*/
    width: -webkit-fill-available;
    width: -moz-available;
}

.spanhgvs{
    width: inherit;
}

.copyTid{
    transition: all 0.5s ease-in-out;
    text-decoration: none;
    color: inherit;
    margin-right: 4px;
}

.phgvs{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.geneShowMore {
    padding: 8px 8px 8px 16px;
    margin-bottom: 0px;
}

.info-subtitle {
    margin-bottom: 8px;
}

.plus-minus {
    margin-right: 5px;
    font-weight: 500;
    display: inline-block;
    width: 10px;
}

.transcript-info {
    margin: 0px;
    padding: 8px 16px;
}

.transcript-info-panel {
    padding: 0px 24px;
}

.select {
    border: solid 1px #5abeba;
    border-radius: 16px;
    padding: 6px 16px;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.25px;
    text-align: center;
}