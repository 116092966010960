.modal {
    color: #344d66;
    font-family: Roboto;
}

label {
    font-family: Roboto Mono;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 8px;
}

.title {
    height: 26px;
    margin-left: 8px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: #344d66;
}

#close {
    width: 48px;
    height: 48px;
    padding: 0px 0px 0px 5px;
    border: solid 2px #efefef;
    border-radius: 25px;
    color: #344d66 !important;
    background-color: #ffffff;
}

#signup,
#signin {
    background-color: #5abeba;
    border: #5abeba;
    font-family: Roboto;
    font-weight: bold;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 1px;
    text-align: center;
}

#reset,
.cancel,
.save, 
.secondary-button {
    height: 35px !important;
    text-align: center !important;
    border: solid 1px #5abeba !important;
    background-color: #ffffff !important;
    color: #344d66 !important;
    font-family: Roboto !important;
    font-size: 13px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1 !important;
    letter-spacing: 0.8px !important;
}

#reset:hover,
.save:hover,
.cancel:hover, 
.secondary-button:hover {
    background-color: rgba(90, 190, 186, 0.08) !important;
}

#signup:hover,
#signin:hover {
    background-color: #4eb1ad;
}

#signup {
    width: 325px;
}

.link,
.check span {
    cursor: pointer;
    color: #0d6efd;
    text-decoration: underline;
}

.link:hover,
.check span:hover {
    color: #0a58ca;
}

.align-right {
    text-align: end;
}

.center {
    text-align: center;
}

.link,
.lab {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
}

.info {
    /*cursor: pointer;*/
    text-align: right;
    margin-right: 0px !important;
}

.tool {
    text-align: left;
    color: #ffffff;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    background-color: #344d66;
    margin: 0;
}

.tool-no-wrap{
    white-space: nowrap;
}


#tooltip {
    --bs-tooltip-bg: #344d66;
    /*opacity: 10;*/
}

.tool-no-wrap .tooltip-inner{
    max-width: fit-content;
}

.new-tag{
    border-radius: 15px;
    user-select: none;
    background-color: rgb(90, 190, 153);
    color: white;
    display: inline-block;
    padding: 0px 4px 0px 4px;
    margin-right: 5px;
    font-family: Roboto;
    font-size: 13px;
    text-align: center;
    align-content: center;
    vertical-align: center;
    cursor: default;
}

.check {
    text-align: center;
    margin-left: 5px;
    font-family: Roboto;
}

/*.check:hover{*/
/*    cursor:pointer;*/
/*}*/

#country {
    border-radius: 4px;
    height: 48px;
    border: solid 1px #efefef;
    background-color: #ffffff;
    padding: 12px 16px;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.2px;
    color: #344d66;
}

#country:hover {
    cursor: pointer;
}

#firstname,
#lastname,
#user,
.pass,
#aff,
#confpass,
#email,
.data input {
    height: 48px;
    border-radius: 4px;
    border: solid 1px #efefef;
    background-color: #ffffff;
}

.det {
    text-align: left;
    /* font-size: 14px; */
    font-family: Roboto Mono;
    font-size: 13px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    margin-bottom: 8px;
}

.col-eye {
    height: 0px;
}

.col-eye svg {
    margin-top: -70px !important;
    cursor: pointer;
    margin-left: 90%;
    margin-right: 0px !important;
}

.body {
    overflow: auto;
    max-height: 85vh !important;
}

.modal-90w {
    max-width: 46% !important;
}

.errorMessage,
.errorMessageProfile {
    color: #f25656;
    text-align: center;
    font-family: Roboto;
    font-size: 15px;
}

.errorMessageProfile {
    text-align: left;
}

.invalid,
.invalid-password {
    color: #f25656 !important;
    font-size: .875em;
}

.invalid-password {
    margin-top: 4px !important;
}