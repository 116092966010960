.results-card {
    font-family: Roboto;
    color: #344d66;
    border-radius: 4px;
    box-shadow: 0 10px 16px 0 rgba(29, 29, 29, 0.04);
    border: solid 1px #efefef !important;
    height: 100% !important;
    max-height: calc(100vh - 412px) !important;
    position: unset !important; 
}

.results-card-header{
    background-color: #ffffff !important;
    border: none !important;
    padding: 16px !important;
}

.results-card-body {
    padding: 0px 16px 16px 16px !important;
    overflow: auto;
}

.results-title {
    display: inline-flex;
}

.results-title p {
    margin-bottom: 0;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    white-space: pre-wrap;
}

.no-results{
    text-align: center;
    padding: 20px;
}

.no-results svg{
    margin: 0 !important;
}

.no-results p{
    font-size: 14px;
    font-weight: bold;
    padding-top: 20px;
    margin-bottom: 0px;
}