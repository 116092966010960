.searchButton {
    border-radius: 0 25px 25px 0px;
    background-color: #5abeba;
    height: 48px;
    /* width: 140px; */
    width: 12%;
    border: solid 1px #efefef;
    box-shadow: 0 10px 16px 0 rgba(29, 29, 29, 0.08) !important;
    border-left: none;
    vertical-align: middle;
}

.divLoader{
    height: 48px;
    width: 12%;
    border-left: none;
    vertical-align: middle;
    display:inline-flex;
    align-items: center;
    padding-left: 10px;
    vertical-align: middle;
}

.spinner-custom{
    margin: 0px 0px 0px 0px !important;
    width: 100% !important;
}

.searchContent {
    display:inline-flex;
    align-items: center;
    vertical-align: middle;
}

.searchButton:hover {
    background-color: #4eb1ad;
}

.Search {
    margin: 0 10px 0px 0px;
    /* font-size: 88%; */
    font-size: 14px;
    font-family: Roboto;
    font-weight: bold;
    text-align: center;
    color: #ffffff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1px;
    text-align: center;
}

@media (max-width: 1150px) {
    .Search {
        display: none;
    }


}

@media (max-width: 1300px) {
    .Search {
        display: none;
        /*font-size: 11px;*/

    }
}