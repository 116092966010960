.info-result-card-body {
  padding: 0px !important;
  background-color: rgba(52, 77, 102, 0.04) !important;
  color: #344d66 !important;
}

.info-result-card {
  --bs-card-spacer-x: 0 !important;
  position: unset !important;
}

.info-button {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  text-align: center;
  color: #344d66;
  padding: 10px 16px;
  border-radius: 4px;
  border: solid 1px #344d66;
  background-color: inherit;
  margin-right: 16px;
  margin-bottom: 16px;
}

.info-button:hover {
  color: #ffffff;
  background-color: #344d66 !important;
}

.info-panel{
  max-height: 400px;
  overflow: auto;
}

.info-title {
  font-size: 19px;
}

.info-table {
  width: 100%;
  table-layout: fixed;
}

.phenotype-info-table th{
  width: 20%;
}

.phenotype-info-table th:nth-child(2){
  width: 40% !important;
}

.info-table span,
.info-span, .info-panel span {
  display: inline-flex;
  align-items: baseline;
  /* justify-content: space-between; */
}

.info-table td,
.info-table th {
  padding: 8px;
}

.info-table th {
  opacity: 0.6;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
}

.info-table p, .infoTable-label {
  margin-bottom: 0px;
}

.info-table a {
  text-decoration: none;
}

.info-table a:hover {
  text-decoration: underline;
}

.infoTable-label {
  opacity: 0.6;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.5px;
  margin-right: 10px;
}

.infoTable-content,
.infoTable-content-gene1,
.infoTable-content-gene2{
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.infoNotAvailable{
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  margin-bottom: 0px;
}

.copy-icon {
  cursor: pointer;
}

.bar {
  background-color: rgba(90, 190, 186, 0.3);
  height: 24px;
  border-radius: 10px;
  max-width: 180px;
}

.bar-percent {
  background-color: #5abeba;
  height: 24px;
  border-radius: 10px;
}


.point-bar{
  background-color: rgba(52, 77, 102, 0.15);
  margin-left: 5px;
}

.point-bar, .point-bar-percent{
  height: 24px;
  width: 24px;
  border-radius: 25px;
}

.showMore{
  text-decoration: none;
  cursor:pointer;
  font-family: Roboto;
  font-size: 13px;
  font-weight:normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;   
}

.showMore:hover{
  text-decoration: underline;
}

.div-header{
  display: inline-block;
  cursor: pointer;
}

.svg-custom{
  vertical-align: middle !important;
}