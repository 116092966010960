.validGene, .invalidGene, .synonymGene, .geneList{
    display: flex;
}

.gene-container{
    display: inline-flex;
}

.buttonValid,
.buttonSynonym,
.buttonInvalid { 
    border-radius: 16px;
    padding-left: 8px;
    padding-right: 8px;
    margin-right: 16px;
    display:inline-flex;
}

.buttonValid {
    background: rgba(90,190,190, 0.25);
}

.buttonSynonym{
    background: rgba(52,77,102, 0.18);
}

.buttonInvalid{
    background: rgba(238,131,115, 0.25);
}

.gene-container:hover{
    margin-right: 0px;
}

.gene-container:hover .close, .buttonSynonym:hover .close, .buttonInvalid:hover .close{
    display: block;
    /* margin-right: 4px; */
}

.gene{
    /* vertical-align: middle; */
    line-height: 1;
    color: #344d66;
    opacity:100 !important;
    padding: 8px;
    margin-bottom: 0px;
}

.close{
    width: 18px;
    height: 18px;
    text-align: center;
    right: 5px;
    color:#efefef;
    background-color: #344d66;
    cursor: pointer;
    display: none;
    border-radius: 25px;
    /* margin-right: 16px; */
    margin: -5px 7px 0 -25px;
}
