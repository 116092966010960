.footerBar {
    height: 80px;
    box-shadow: 0 2px 16px 0 rgba(29, 29, 29, 0.08);
    background-color: #344d66;
    bottom: 0;
}

.term,
.privacy, .signature {
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #ffffff;
    padding: 30px 10px 28px 25px;
    text-align: center;
    z-index: 1;
    align-items: center;
    display: flex;
    justify-content: center;
}

.signature{
    font-size: 11px !important;
    opacity: 0.4 !important;
}

.privacy:hover,
.term:hover {
    background-color: #2b384e;
    cursor: pointer;
}