.accordion-item, .accordion-header{
    border-radius: 4px !important;
}

.accordion-header:hover .accordion-button, .accordion-header:hover{
    background-color: #344d66;
    color:#ffffff !important;
    cursor: pointer;
    transition:  all 0.3s ease;

}

.accordion-header:hover .accordion-button::after{
    background-image: url(../../../Images/arrow-down-white.svg) !important;
}

.accordion-header:hover .results-icon-blue{
    fill: #ffffff;
    stroke: #ffffff;
    transition:  all 0.3s ease;

}

.accordion-header:hover .results-icon-white{
    stroke: #ffffff;
    transition:  all 0.3s ease;

}

.accordion-header:hover .predict-col-img img[predict='TD'], .accordion-button:not(.collapsed) .predict-col-img img[predict='TD']{
    content:  url(../../../Images/TDWhite.png) !important;
}

.accordion-header:hover  .predict-col-img img[predict='CO'], .accordion-button:not(.collapsed) .predict-col-img img[predict='CO']{
    content:  url(../../../Images/COWhite.png) !important;
}

.accordion-header:hover .predict-col-img img[predict='DMD'], .accordion-button:not(.collapsed) .predict-col-img img[predict='DMD']{
    content:  url(../../../Images/DMDWhite.png) !important;
}

.accordion-header:hover .predict-col-img img[predict='NA'], .accordion-button:not(.collapsed) .predict-col-img img[predict='NA']{
    content:  url(../../../Images/NAMechanismwhite.png) !important;
}
.accordion-button::after{
    background-image: url(../../../Images/arrow-down-blue.svg) !important;
    background-position: center;
    border-radius: 25px;
    border: solid 1px #5abeba;
    padding: 4px;
    width: 32px !important;
    height: 32px !important;
    margin-left: 0 !important;
    margin-right: 16px !important;
    transition:  all 0.3s ease;

}

.accordion-button:not(.collapsed)::after{
    background-image: url(../../../Images/arrow-down-white.svg) !important;
    background-position: center;
    transition:  all 0.3s ease;

}

.accordion-button:not(.collapsed){
    background-color: #344d66 !important;
    color: #ffffff !important;
    transition:  all 0.3s ease;

}

.accordion{
--bs-accordion-border-width:none !important;
 --bs-accordion-btn-focus-box-shadow: 0 0 0 0 !important;
}

.accordion-button{
    padding: 0px !important;
    border-radius: 4px !important;
    background-color: rgba(52, 77, 102, 0.04) !important;
    width: 100%;
    position: unset !important;
}

.accordion-item{
    margin-bottom: 16px !important;
}

.accordion .accordion-item:last-child {
    margin-bottom: 0px !important;
}

.accordion-collapse{
    background-color: rgba(52, 77, 102, 0.04) !important;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.info-result-card{
    border: unset !important;
}

.accordion-body{
    padding: 16px !important;
}